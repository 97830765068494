
import { Component, Vue } from 'nuxt-property-decorator'
import { Swiper, SwiperSlide } from 'swiper-vue2'
import ReviewItem from '~/components/ReviewItem.vue'
import { STUB_REVIEWS } from '@/stubs/reviews'
import 'swiper/swiper-bundle.css'

@Component({
  components: {
  ReviewItem,
  Swiper,
  SwiperSlide
  }
  })
export default class extends Vue {
  reviews: any[] = []
  swiper: null

  async fetch () {
    await this.fetchReviews()
  }

  async fetchReviews () {
    try {
      // if (process.server) {
      //   this.reviews = STUB_REVIEWS
      //   return
      // }
      const { data: reviews } = await this.$axios.$get(process.env.API_ENDPOINT + 'comments', {
        // @ts-ignore
        requireToken: false,
        cache: true,
        params: {
          client_app_id: process.env.APP_ID,
          page: 0,
          page_size: 6
        }
      })

      this.reviews = reviews
    } catch (err) {
      this.reviews = STUB_REVIEWS
      this.$sentry.captureException(err)
    }
  }

  onSwiper (swiper) {
    this.swiper = swiper
  }

  nextSlide () {
    if (this.swiper) {
      // @ts-ignore
      this.swiper.slideNext()
    }
  }

  prevSlide () {
    if (this.swiper) {
      // @ts-ignore
      this.swiper.slidePrev()
    }
  }
}
