
export default {
  data () {
    return {
      isVisible: false
    }
  },

  mounted () {
    // @ts-ignore
    window.addEventListener('scroll', this.handleScroll)
  },

  beforeDestroy () {
    // @ts-ignore
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll () {
      // @ts-ignore
      this.isVisible = document.body.scrollTop > 200 || document.documentElement.scrollTop > 200
    },

    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
