var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"reviews",attrs:{"itemtype":"http://schema.org/Product","itemscope":""}},[_c('div',{staticClass:"background"},[_c('div',{staticClass:"reviews_container"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"reviews_container_box"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"button-left",on:{"click":_vm.prevSlide}},[_c('svg',{attrs:{"width":"15","height":"24","viewBox":"0 0 15 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M13 2.00244L3 12.0019L13 22.0024","stroke":"#113A59","stroke-width":"3"}})])]),_vm._v(" "),_c('div',{staticClass:"reviews_container_box_scroll"},[_c('meta',{attrs:{"itemprop":"name","content":"gos-oplata.ru"}}),_vm._v(" "),_c('swiper',{attrs:{"slides-per-view":'auto',"space-between":45,"centered-slides":true,"loop":true,"breakpoints":{
              992: {
                spaceBetween: 30,
                slidesPerView: 3
              }
            },"simulate-touch":false},on:{"swiper":_vm.onSwiper}},_vm._l((_vm.reviews),function(review,index){return _c('swiper-slide',{key:index},[_c('review-item',{attrs:{"review":review}})],1)}),1),_vm._v(" "),_vm._m(2)],1),_vm._v(" "),_c('div',{staticClass:"button-right",on:{"click":_vm.nextSlide}},[_c('svg',{attrs:{"width":"15","height":"24","viewBox":"0 0 15 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2 2.00244L12 12.0019L2 22.0024","stroke":"#113A59","stroke-width":"3"}})])]),_vm._v(" "),_vm._m(3)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"reviews_container_header"},[_c('h2',{staticClass:"reviews_container_header__title"},[_vm._v("\n          Отзывы\n        ")]),_vm._v(" "),_c('div',{staticClass:"reviews_container_header_img"},[_c('div',{staticClass:"for-image"},[_c('div',{staticClass:"image-main"}),_vm._v(" "),_c('p',{staticClass:"rate-number"},[_vm._v("\n              4,9\n            ")]),_vm._v(" "),_c('div',{staticClass:"image-stars"}),_vm._v(" "),_c('div',{staticClass:"block-stars"},[_c('div',{staticClass:"image-star full-star"}),_vm._v(" "),_c('div',{staticClass:"image-star full-star"}),_vm._v(" "),_c('div',{staticClass:"image-star full-star"}),_vm._v(" "),_c('div',{staticClass:"image-star full-star"}),_vm._v(" "),_c('div',{staticClass:"image-star not-full-star"})])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"block-left"},[_c('div',{staticClass:"gradient-left"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"itemprop":"aggregateRating","itemscope":"","itemtype":"http://schema.org/AggregateRating"}},[_c('meta',{attrs:{"itemprop":"ratingValue","content":"4.9"}}),_vm._v(" "),_c('meta',{attrs:{"itemprop":"bestRating","content":"5"}}),_vm._v(" "),_c('meta',{attrs:{"itemprop":"worstRating","content":"1"}}),_vm._v(" "),_c('meta',{attrs:{"itemprop":"ratingCount","content":"6"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"block-right"},[_c('div',{staticClass:"gradient-right"})])
}]

export { render, staticRenderFns }