
import FaqQuestion from '~/components/info-block/FaqQuestion.vue'

export default {
  components: {
    FaqQuestion
  },
  data () {
    return {
      faqList: [{
        question: 'Как оплатить штрафы ГИБДД без комиссии?',
        answer: '<p>Если вы наткнулись на онлайн-сервис, на котором можно оплатить штрафы ГИБДД без комиссии, скорее всего, это дело рук мошенников, и лучше сразу закрыть страничку. После того, как вы внесете платеж по квитанции, со счета деньги спишутся, а вот из базы госавтоинспекции задолженность не пропадет. Это частая схема, которой пользуются недобросовестные &quotбизнесмены&quot. Не рискуйте! Комиссия вовсе не значит, что мы хотим нажиться на вас. Небольшой сервисный сбор есть даже в государственных сервисах.</p><br><p>Сумма, которая взимается на ГосОплате при погашении штрафа, позволяет нам обеспечить качественное функционирование приложения, обновление технической базы данных, а также поддержание безопасности и надежности проведения платежей. Мы стараемся предоставить нашим клиентам все удобные способы погашения задолженности и доступ к сайту круглосуточно без задержек.</p>'
      },
      {
        question: 'Где найти УИН на квитанции?',
        answer: '<p>Один из наиболее простых, но не самых удобных способов проверки и оплаты штрафа состоит в использовании уникального идентификационного номера (УИН) постановления. В платежных документах этот код состоит из 20-25 цифр и имеет различные названия в зависимости от вида начислений. В квитанциях об оплате штрафов ГИБДД УИН обычно указывается в графе «Постановление», а в документах на оплату налоговых начислений — в графе «Индекс документа».</p><br><p>Если вы уже получили постановление по почте, вам достаточно ввести цифры в соответствующее поле проверки на сайте, и штраф сразу отобразится, а вы сможете его оплатить. Однако, если постановление было выписано вручную инспектором ДПС, он также будет зарегистрирован в базе данных ГИБДД, но получит свой УИН для погашения в течение нескольких дней, когда оператор обработает информацию о нарушении.</p>'
      },
      {
        question: 'Приходят штрафы на проданный автомобиль',
        answer: '<p>Убедитесь, что сделка по продаже транспортного средства была оформлена правильно, и все документы у вас на руках. Если новый владелец не поставил машину на учет в ГИБДД, то по базе авто по-прежнему числится за вами, и все штрафы будут приходить на ваше имя, поэтому и оплату требуют с вас. Если с момента продажи прошло уже 10 дней, следует самостоятельно снять его с учета. Для этого нужно обратиться в ГИБДД с документами о продаже. Перед визитом в инспекцию рекомендуется связаться с новым владельцем. Возможно, он сам не в курсе о штрафах. Попросите его зарегистрировать автомобиль на себя и погасить все полученные задолженности. В спорной ситуации несправедливое постановление также можно обжаловать: если с момента вынесения прошло меньше 10 дней, подайте заявление в ГИБДД; если больше — прямиком в суд.</p>'
      },
      {
        question: 'Я оплатил штраф, но он отображается как неоплаченный',
        answer: '<p>Возможные <b>причины задержки</b> погашения штрафа</p><ol><li>Платеж находится в процессе обработки в системах ГИБДД и Федерального казначейства. Обычно он проходит в течение 1 рабочего дня, в крайнем случае — в течение 2-3 дней.</li><li>Неполная оплата штрафа. Возможно, вы оплатили штраф частично, например, воспользовались скидкой, срок действия которой уже истек.</li></ol><br><p><b>Что делать?</b></p><p>Если вы оплатили штраф через сервис ГосОплата, но задолженность не погасилась, обратитесь в нашу службу поддержки <span>support@gos-oplata.ru</span>. Чтобы ускорить решение проблемы, напишите УИН начисления. Мы сами свяжемся с банком, уточним, переданы ли сведения об оплате в ГИС ГМП и добьемся, чтобы зависшие в системе деньги были отправлены по назначению.</p>'
      },
      {
        question: 'Чем грозит неоплаченный вовремя штраф?',
        answer: '<p>На оплату дается 60 дней. Уклонение от погашения задолженности считается еще одним правонарушением и влечет за собой ответственность, установленную статьей <u>20.25 КоАП РФ</u>. В первую очередь это еще один административный штраф, в два раза превышающий сумму неуплаченного, но не менее 1 тыс. руб. Затем дело передается в ФССП. У приставов есть рычаги воздействия, с помощью которых они могут значительно насолить неплательщику. Начнут они с того, что заблокируют банковские счета должника или принудительно спишут с них необходимую сумму. Даже за небольшие долги в размере 500 рублей накладывается арест на имущество. А при более серьезных суммах обстановка накаляется: лицу временно ограничивается выезд за границу, возможна конфискация имущества в пользу государства или лишение водительских прав. В отношении злостных неплательщиков могут применяться такие меры как исправительные работы (до 50 часов) или административный арест (до 15 суток). Поэтому лучше узнавать обо всех задолженностях и оплачивать их вовремя.</p>'
      },
      {
        question: 'Проверить штрафы по правам водителя',
        answer: '<p>Дорожная камера привязывает нарушение к собственнику автомобиля, при этом не важно, кто был за рулем. Инспектор ДПС составляет протокол на водителя, и такие штрафы нельзя найти по госномеру или другим документам. На сайте ГИБДД нет возможности проверить штрафы по правам, но оплачивать их все равно нужно. На сервисе ГосОплата вы можете быстро получить полную информацию о правонарушениях, зафиксированных сотрудниками ГИБДД на ваше ВУ, даже если вы управляли автомобилем, зарегистрированным на другое лицо.<br>Если вы недавно получили новое водительское удостоверение, но на старое были выписаны штрафы, прежде чем сдать права в ГИБДД, сделайте их копию или сохраните номер для отдельной проверки.</p>'
      },
      {
        question: 'Пришел штраф без фото',
        answer: '<p>Чтобы получить полную информацию по нарушению, используйте для проверки госномер авто и СТС.</p><br><p>Что делать, если штраф пришел без фотографии:</p><ol><li>Проверить позже: Дождитесь некоторое время и попробуйте снова проверить наличие фотографии. Часто фото появляется через несколько дней после выписки штрафа.</li><li>Обратиться в ГИБДД: Если фото так и не появилось, обратитесь в отделение ГИБДД, выписавшее штраф. Там могут предоставить дополнительные данные и объяснить причину отсутствия фото.</li><li>Подать запрос: Направьте письменный запрос в ГИБДД с просьбой предоставить фотографическое доказательство нарушения. Образцы таких запросов можно найти на официальных сайтах ГИБДД или в интернете.</li><li>Оспорить штраф: Если вы уверены, что штраф был выписан ошибочно и бездоказательно, вы имеете право его оспорить. Для этого подайте жалобу в вышестоящий орган ГИБДД или в суд.</li></ol><br><p>Соблюдение этих шагов поможет вам разобраться в ситуации и получить необходимые доказательства правонарушения, если таковые действительно существуют.</p>'
      }]
    }
  }
}
