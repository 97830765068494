export const STUB_REVIEWS = [
  {
    id: 18,
    full_name: 'Анастасия Владимировна',
    email: 'alinasimpleseo@gmail.com',
    content: 'Штраф был погашен за 30 минут. Сайт работает отлично',
    admin_response: '',
    rating: 4,
    is_published: 1,
    created_at: '2021-07-15 00:00:00',
    updated_at: '2023-02-06 11:05:57',
    client_app_id: 'gosoplata_web_app'
  },
  {
    id: 11,
    full_name: 'Вячеслав Кадочников',
    email: 'kadochnikovveaceslav@yandex.ru',
    content: 'Быстрая и удобная проверка и оплата',
    admin_response: null,
    rating: 5,
    is_published: 1,
    created_at: '2021-07-14 00:00:00',
    updated_at: '2021-07-14 18:27:26',
    client_app_id: 'gosoplata_web_app'
  },
  {
    id: 7,
    full_name: 'Кирилл',
    email: 'evgenius291987@yandex.ru',
    content: 'Самое быстрое погашение штрафов ГИБДД за 10 минут, проверка быстрая, сайт в целом намного удобнее чем другие сайты проверки и оплаты задолженностей, например сайты банков и офиц. сайт ГИБДД',
    admin_response: null,
    rating: 5,
    is_published: 1,
    created_at: '2021-07-09 00:00:00',
    updated_at: '2021-07-14 18:28:25',
    client_app_id: 'gosoplata_web_app'
  },
  {
    id: 1,
    full_name: 'Павел Матусевич',
    email: 'pavelmatuceich558@gmail.com',
    content: 'На сервисе можно быстро проверить все штрафы и долги которые только есть - по налогам, штрафы ГАИ, долги перед приставами. Оплатить можно сразу все найденные штрафы одним платежом. Штрафы ГИБДД можно оплатить со скидкой 50%. В целом очень удобная вещь, скачал приложение и получаю пуш-уведомления о всех новых долгах. Места в смартфоне занимает мало. Есть комиссия, но она вполне адекватная, я готов переплатить эти копейки, зато быть уверенным, что долг будет погашен, а не бегать потом по всем инстанциям. Платежи проходят быстро, в моем случае было быстрее чем через сайт банка.',
    admin_response: null,
    rating: 5,
    is_published: 1,
    created_at: '2021-07-06 00:00:00',
    updated_at: '2021-08-09 17:54:00',
    client_app_id: 'gosoplata_web_app'
  },
  {
    id: 2,
    full_name: 'Александра',
    email: 'alexkitty1230@mail.ru',
    content: 'Отличный сервис и приложение! Экономит время, выдает чек, техподдержка оперативно отвечает на любые вопросы. Оплачивала налоговые долги.\r\n',
    admin_response: null,
    rating: 5,
    is_published: 1,
    created_at: '2021-03-28 00:00:00',
    updated_at: '2021-07-14 18:27:57',
    client_app_id: 'gosoplata_web_app'
  }
]
