
import { Component, Vue } from 'nuxt-property-decorator'
import { Severity } from '@sentry/types'
import { Page } from '~/types/Pages'

@Component
export default class extends Vue {
  articles: Page[] = []

  async fetch () {
    // @ts-ignore
    this.articles = Object.freeze(await this.$static.getPageList('news', 3, {
      params: {
        preview: 'true'
      }
    }).then(data =>
      data.map((article: Page) => ({
        alias: article.alias,
        title: article.title
      }))
    ).catch(() => {
      this.$sentry.captureMessage('Ошибка при получении списка статей', Severity.Warning)
    }))
  }
}
